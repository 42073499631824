<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TourismFilter :hasTypeDeal="false"></TourismFilter>
    <BannerSection location="homepage-top"></BannerSection>
    <section class="tourism-container">
      <div class="d-flex align-items-center justify-content-between flex-wrap m-t-20">
        <h4>
          All flights
        </h4>
        <div class="d-flex fs14">
          <p class="mx10">
            {{ $t( "TOURISM.DATE_RANGE" ) }}: <b>01/04/2021 - 10/04/2021</b>
          </p>
          <p class="mx10">
            {{ $t( "TOURISM.DURATION" ) }}: <b>3 - 5 Nights</b> 
          </p>
          <p class="mx10">
            {{ $t( "TOURISM.TRAVELERS" ) }}: <b>2 Adults</b> 
          </p>
        </div>
        <div class="d-flex">
          <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
          <FilterTourism></FilterTourism>
        </div>
      </div>
      <div class="row m-t-5">
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
        <div class="col-md-3">
          <FlightTourismItem>
            <p class="fs14 text-center" slot="title">
              Flights to <b>Paris, France</b>
            </p>
            <button 
              class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
              slot="actionPart" 
              @click="openFullDetailModal()"
            >
              <img src="/assets/img/icons/info.svg" alt="" class="">
              {{ $t( "TOURISM.FULL_DETAILS" ) }}
            </button>
            <router-link
              :to="{
                name: 'FlightDestinationDates',
              }"
              slot="routerBlock" 
            >
              <button class="primary-btn small-btn fs16 bg-3">
                {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
              </button>
            </router-link>
          </FlightTourismItem>
        </div>
      </div>
    </section>
    <FullDetailModal></FullDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../components/BannerSection";
import TourismFilter from "../../../components/tourism/TourismFilter";
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";
import FlightTourismItem from "../../../components/tourism/flight/FlightTourismItem";
import FullDetailModal from "../../../components/tourism/flight/FullDetailModal.vue";
export default {
  name: "SelectedResult",
  components: {
    BannerSection,
    TourismFilter,
    FilterTourism,
    SortTourism,
    FlightTourismItem,
    FullDetailModal
  },
  methods: {
    sortDeals() {
    },
    openFullDetailModal() {
      this.$modal.show("FullDetailModal");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
</style>